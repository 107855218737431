import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, userInfo, ...rest }) => {
  const adminPath = rest.path.includes("/admin/");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!userInfo?.email) {
          return <Redirect to="/login" />;
        }

        if (!userInfo?.active) {
          return <Redirect to="/verifyEmail" />;
        }

        // if (adminPath && !userInfo?.isAdmin) {
        //   return <Redirect to="/" />;
        // }

        return <Component {...props} />;
      }}
    />
  );
}

export default ProtectedRoute;
