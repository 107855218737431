import React, { useEffect, useMemo } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import PaginateRequest from '../components/PaginateRequest';
import {
  listMyRequestsAsChampion,
  deleteRequest,
  createRequest,
} from '../actions/requestActions';
import { REQUEST_CREATE_RESET } from '../constants/requestConstants';
import { sortingFunctionForDate, sortingFunctionForIsSubmitted } from '../utils/sortingFunctions';
import FilterComponent from '../components/FilterComponent';
import DataTable from 'react-data-table-component';
import { REQUEST_PHASES } from '../constants/RequestPhases';

const RequestListChampionScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1;
  const dispatch = useDispatch();

  const requestListMyChampion = useSelector(
    (state) => state.requestListMyChampion
  );
  const { loading, error, requests, page, pages } = requestListMyChampion;

  const requestDelete = useSelector((state) => state.requestDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = requestDelete;

  const requestCreate = useSelector((state) => state.requestCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    request: createdRequest,
  } = requestCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: REQUEST_CREATE_RESET });
    if (successCreate) {
      history.push(`/request/${createdRequest._id}/edit`);
    } else {
      dispatch(listMyRequestsAsChampion('', pageNumber));
    }
  }, [
    userInfo,
    successDelete,
    successCreate,
    createdRequest,
    pageNumber,
  ]);

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteRequest(id));
    }
  };

  // const createRequestHandler = () => {
  //   dispatch(createRequest());
  // };

  const columns = [
    {
      name: '',
      selector: row => (<LinkContainer to={`/request/${row._id}/view`}>
        <Button variant="light" className="btn-sm">
          <i className="fas fa-external-link-square-alt"></i>
        </Button>
      </LinkContainer>),
      sortable: true,
      wrap: true,
    },
    {
      name: 'ID',
      selector: row => row.ecrId,
      sortable: true,
      wrap: true,
    },
    {
      name: 'TITLE',
      selector: row => row.title,
      sortable: true,
      wrap: true,
    },
    {
      name: 'INITIATED BY',
      selector: row => row.initiatedBy,
      sortable: true,
      wrap: true,
    },
    {
      name: 'ECR CHAMPION',
      selector: row => row.ecrChampion,
      sortable: true,
      wrap: true,
    },
    {
      name: 'PRODUCTS AFFECTED',
      selector: row => row.productsAffected,
      sortable: true,
      wrap: true,
    },
    {
      name: 'IS SUBMITTED',
      selector: row => row.isSubmitted ? (
        <i
          className="fas fa-check"
          style={{ color: 'green' }}
        ></i>
      ) : (
        <i className="fas fa-times" style={{ color: 'red' }}></i>
      ),
      sortFunction: sortingFunctionForIsSubmitted,
      wrap: true,
    },
    {
      name: 'STAGE',
      selector: row => REQUEST_PHASES[row.currentStep],
      sortable: true,
      sortFunction: (a, b) => a.currentStep - b.currentStep,
      wrap: true,
    },
    {
      name: 'CREATED AT',
      selector: row => row.createdAt,
      sortFunction: (a, b) => sortingFunctionForDate(a.createdAt, b.createdAt),
      wrap: true,
    },
    {
      name: 'UPDATED AT',
      selector: row => row.updatedAt,
      sortFunction: (a, b) => sortingFunctionForDate(a.updatedAt, b.updatedAt),
      wrap: true,
    },
    {
      name: 'ACTIONS',
      selector: row => (
        <>
          <LinkContainer to={`/request/${row._id}/edit`}>
            <Button variant="light" className="btn-sm">
              <i className="fas fa-edit"></i>
            </Button>
          </LinkContainer>
          <Button
            variant="danger"
            className="btn-sm"
            onClick={() => deleteHandler(row._id)}
          >
            <i className="fas fa-trash"></i>
          </Button>
        </>
      ),
      wrap: true,
    }
  ];

  const data = requests.map(({ _id, ecrId, title, initiatedBy, ecrChampion, productsAffected, isSubmitted, progress: { currentStep }, createdAt, updatedAt }) => {
    return {
      _id,
      ecrId: ecrId ? ecrId : '',
      title,
      initiatedBy: initiatedBy.name,
      ecrChampion: ecrChampion.name,
      productsAffected: productsAffected.length > 0 ? productsAffected.map(p => p.name).join("|| ") : '',
      isSubmitted,
      currentStep,
      createdAt: new Date(createdAt).toLocaleDateString('en-US'),
      updatedAt: new Date(updatedAt).toLocaleDateString('en-US'),
    }
  })

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );

  const filteredItems = data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );


  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);


  return (
    <>
      <Row className="align-items-center">
        <Col>
          <h3>
            MY CHANGE REQUESTS <br></br>
            <h6>(As Champion)</h6>
          </h3>
        </Col>
        <Col className="text-right">
          {/* <Button className="my-3" onClick={createRequestHandler}>
            <i className="fas fa-plus"></i> Create New ECR
          </Button> */}
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant="danger">{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <DataTable
            columns={columns}
            data={filteredItems}
            defaultSortFieldId={2}
            defaultSortAsc={false}
            highlightOnHover
            striped
            responsive
            subHeader
            subHeaderComponent={subHeaderComponent}
            pagination
          />
        </>
      )}
    </>
  );
};

export default RequestListChampionScreen;
