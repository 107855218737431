export const INITIATION = 0;
export const INITIATION_APPROVAL = 1;
export const IMPLEMENTATION_TASK_LIST = 2;
export const CERTIFICATION_APPROVAL = 3;
export const IMPLEMENTATION_APPROVAL = 4;
export const POST_IMPLEMENTATION_APPROVAL = 5;
export const WITHDRAWN = 6;

export const RequestPhases = {
    initiation: INITIATION,
    initiated: INITIATION_APPROVAL,
    tasklist: IMPLEMENTATION_TASK_LIST,
    certification: CERTIFICATION_APPROVAL,
    implementation: IMPLEMENTATION_APPROVAL,
    postimplement: POST_IMPLEMENTATION_APPROVAL,
    withdrawn: WITHDRAWN,
}

export const REQUEST_PHASES = {
    [INITIATION]: "Initiation",
    [INITIATION_APPROVAL]: "Initiation Approval",
    [IMPLEMENTATION_TASK_LIST]: "Implementation Task List",
    [CERTIFICATION_APPROVAL]: "Certification Approval",
    [IMPLEMENTATION_APPROVAL]: "Implementation Approval",
    [POST_IMPLEMENTATION_APPROVAL]: "Post Implementation Approval",
    [WITHDRAWN]: "Withdrawn",
}

/**
 * RequestPhases = [
 *  {
 *      name: '',
 *      value: '',
 *  },
 *  ...
 * ]
 */

/*
[
    {"name": "Initiation",},
    {"name": "Initiation Approval",},
    {"name": "Implementation Task List",},
    {"name": "Certification Approval",},
    {"name": "Final Approval / Closing ECR",},
    {"name": "Post Final Approval",}
]
*/