import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, Container, NavDropdown, Image } from 'react-bootstrap';
import { logout } from '../actions/userActions';
import CreateRequestButton from "./CreateRequestButton";
import { link } from '../utils/link';

const Header = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <header>
      <Navbar className="color-nav" variant="dark" expand="lg" collapseOnSelect>
        <Container>
          <LinkContainer to={link("/")}>
            <Navbar.Brand>
              <Image
                src="/images/unirac.png"
                style={{
                  backgroundColor: '#fff',
                  width: '200px',
                  height: '60px',
                  padding: '10px',
                }}
              ></Image>
            </Navbar.Brand>
          </LinkContainer>
          {userInfo?.active ?
            <>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                {/* <Route render={({ history }) => <SearchBox history={history} />} /> */}
                <Nav className="ml-auto">
                  <LinkContainer to="/requestlistAsUser">
                    <Nav.Link className="nav-link">
                      <i className="fas fa-list"></i> All ECRs
                    </Nav.Link>
                  </LinkContainer>
                  <NavDropdown title="My Requests" id="requestsmenu">
                    <LinkContainer to="/requestlistAsInitiator">
                      <NavDropdown.Item>As Initiator</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/requestlistAsChampion">
                      <NavDropdown.Item>As Champion</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/requestlistAsTaskholder">
                      <NavDropdown.Item>As Taskholder</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                  <NavDropdown title={userInfo.name} id="username">
                    <LinkContainer to="/profile">
                      <NavDropdown.Item>Profile</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item onClick={logoutHandler}>
                      Logout
                    </NavDropdown.Item>
                    <LinkContainer to="/invite">
                      <NavDropdown.Item>Invite</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                  {userInfo.isAdmin && (
                    <NavDropdown title="Admin" id="adminmenu">
                      <LinkContainer to="/admin/userlist">
                        <NavDropdown.Item>Users</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/admin/grouplist">
                        <NavDropdown.Item>Groups</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/admin/productlist">
                        <NavDropdown.Item>Products</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/admin/requestlist">
                        <NavDropdown.Item>Requests</NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>
                  )}
                </Nav>
                <CreateRequestButton />
              </Navbar.Collapse>
            </>
            :
            <>
              {userInfo?.email ?
                <NavDropdown title={userInfo.name} id="username">
                  <LinkContainer to="/profile">
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                  <LinkContainer to="/invite">
                    <NavDropdown.Item>Invite</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
                :
                <LinkContainer to="/login">
                  <Nav.Link className="nav-link">
                    <i className="fas fa-user"></i> Sign In
                  </Nav.Link>
                </LinkContainer>
              }
            </>
          }
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
