import React, { useEffect, useMemo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { deleteUser, listUsers } from '../actions/userActions';
import FilterComponent from '../components/FilterComponent';
import Loader from '../components/Loader';
import Message from '../components/Message';

const UserListScreen = () => {
  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete } = userDelete;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin)
      dispatch(listUsers());
  }, [successDelete, userInfo]);

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteUser(id));
    }
  };

  const columns = [
    {
      name: 'NAME',
      selector: row => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: 'EMAIL',
      selector: row => (<a href={`mailto:${row.email}`}>{row.email}</a>),
      sortable: true,
      wrap: true,
    },
    {
      name: 'ADMIN',
      selector: row => row.isAdmin ? (
        <i className="fas fa-check" style={{ color: 'green' }}></i>
      ) : (
        <i className="fas fa-times" style={{ color: 'red' }}></i>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: 'ACTIVE',
      selector: row => row.active ? (
        <i className="fas fa-check" style={{ color: 'green' }}></i>
      ) : (
        <i className="fas fa-times" style={{ color: 'red' }}></i>
      ),
      sortable: true,
      wrap: true,
    },
    (userInfo.isAdmin && {
      name: '',
      selector: row => (
        <>
          <LinkContainer to={`/admin/user/${row._id}/edit`}>
            <Button variant="light" className="btn-sm">
              <i className="fas fa-edit"></i>
            </Button>
          </LinkContainer>
          <Button
            variant="danger"
            className="btn-sm"
            onClick={() => deleteHandler(row._id)}
          >
            <i className="fas fa-trash"></i>
          </Button>
        </>
      ),
      sortable: true,
      wrap: true,
    }),
  ];

  let data = users?.map(user => user) ?? [];


  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );

  const filteredItems = data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <Row className="align-items-center">
        <Col>
          <h1>USERS</h1>
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <DataTable
          columns={columns}
          data={filteredItems}
          defaultSortFieldId={2}
          defaultSortAsc={false}
          highlightOnHover
          striped
          responsive
          subHeader
          subHeaderComponent={subHeaderComponent}
          pagination />
      )}
    </>
  );
};

export default UserListScreen;
