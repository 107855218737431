import React from 'react';
import { Form } from 'react-bootstrap';

const ModalForm = ({ changeInput }) => {
  return (
    <Form>
      <Form.Group>
        <Form.Control
          style={{ color: 'black', fontSize: '1rem' }}
          type="name"
          as="textarea"
          rows={3}
          placeholder="Enter Comments"
          onChange={(e) => changeInput(e.target.value)}
        ></Form.Control>
      </Form.Group>
    </Form>
  );
};

export default ModalForm;
