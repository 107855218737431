import React from "react";
import HelpIcon from '@material-ui/icons/Help';

const ToolTip = ({description}) => (
    <div className="Tooltip-Wrapper">
      <HelpIcon className="helpIcon" />
      <div className={`Tooltip-Tip`}>{description}</div>
    </div>
);

export default ToolTip;