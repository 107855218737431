import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#0083c2' }}>
      <Container>
        <Row>
          <Col
            style={{ color: '#fff', fontWeight: 'bold' }}
            className="text-center py-3"
          >
            Copyright &copy; Unirac
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
