import { ERROR, LOADING, MESSAGE, RESET } from "../constants/appConstants";
import appState from "../constants/appState";

// state -> total app state = store.getState() here
// default state -> appState
export const appReducer = (state = appState, action) => {
    switch (action.type) {
        case LOADING:
            return { ...state, loading: true };
        case MESSAGE:
            return { ...state, message: action.payload };
        case ERROR:
            return { ...state, error: action.payload };
        case RESET:
            return state;
        default:
            return state;
    }
}