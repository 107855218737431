import { Checkbox, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import Creatable from "react-select/creatable";
import Tooltip from "react-tooltip-lite";
import { v4 as uuidv4 } from "uuid";
import { listGroups } from "../actions/groupActions";
import { listProducts } from "../actions/productActions";
import { listRequestDetails, updateRequest } from "../actions/requestActions";
import { listUsersForEcr } from "../actions/userActions";
import CommentBox from "../components/CommentBox";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  REQUEST_DETAILS_RESET,
  REQUEST_UPDATE_RESET,
} from "../constants/requestConstants";
import ToolTip from "../components/ToolTip";

// using material-ui core styles
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  form: {
    width: "300%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const RequestViewScreen = ({ match, history }) => {
  const classes = useStyles();
  const requestId = match.params.id;

  // state
  const [title, setTitle] = useState("");
  const [problemStatement, setProblemStatement] = useState("");
  const [changesRequired, setChangesRequired] = useState("");
  const [productsAffected, setProductsAffected] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [progress, setProgress] = useState({});
  const [ehaComments, setEhaComments] = useState("");
  const [saComments, setSaComments] = useState("");
  const [ccComments, setCcComments] = useState("");
  const [engineeringValidationComments, setEngineeringValidationComments] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [ecrChampion, setEcrChampion] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [comments, setComments] = useState([]);
  const [taskFields, setTaskFields] = useState([]);
  const [docSpecFields, setDocSpecFields] = useState([]);
  const [filesInfo, setFilesInfo] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [uploading, setUploading] = useState(false);

  const uploadFileHandler = async (files) => {
    const formData = new FormData();
    let filesList = [];
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
      filesList.push(files[i].name);
    }
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("/api/upload", formData, config);

      setFilesInfo(
        [...filesInfo].concat(
          data.files.map(({ id, filename }) => {
            return {
              uuid: uuidv4(),
              id,
              filename,
              fileUrl: `${process.env.REACT_APP_BASE_URL}/files/download/${filename}`,
            };
          })
        )
      );

      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadFileHandlerCustom = async (e, id) => {
    const files = e.target.files;
    const formData = new FormData();
    let filesList = [];
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
      filesList.push(files[i].name);
    }
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("/api/upload", formData, config);

      const newInputFields = docSpecFields.map((i) => {
        if (id === i.id) {
          i["file"] = data.files[0];
        }
        return i;
      });
      setDocSpecFields(newInputFields);

      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  // convert to ISO Date
  const convertISODate = (mydate) => {
    const date = new Date(mydate);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return month + "/" + dt + "/" + year;
  };

  // For Tasks

  const handleChangeTaskInput = (id, event) => {
    const newInputFields = taskFields.map((i) => {
      if (id === i.id) {
        if (event.target !== null && event.target.id === "completed") {
          i["completed"] = !i["completed"];
        } else i[event.target.name] = event.target.value;
      }
      return i;
    });
    setTaskFields(newInputFields);
  };

  const handleAddTaskFields = () => {
    setTaskFields([
      ...taskFields,
      {
        id: uuidv4(),
        taskAssigned: userInitial,
        taskDetails: "",
        completed: false,
      },
    ]);
  };

  const handleRemoveTaskFields = (id) => {
    const values = [...taskFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setTaskFields(values);
  };

  const handleUpdateTaskFields = (tasks) => {
    let myTasks = [];
    tasks.forEach(({ assignedTo, details, completed }) => {
      let assignedUsersAndGroups = [];
      if (usersList.length > 0 && assignedTo.users.length > 0) {
        usersList.forEach((u) => {
          assignedTo.users.forEach((a) => {
            if (a === u.value) assignedUsersAndGroups.push(u);
          });
        });
      }
      if (groupsList.length > 0 && assignedTo.groups.length > 0) {
        groupsList.forEach((u) => {
          assignedTo.groups.forEach((a) => {
            if (a === u.value) assignedUsersAndGroups.push(u);
          });
        });
      }

      myTasks.push({
        id: uuidv4(),
        taskAssigned: assignedUsersAndGroups,
        taskDetails: details,
        completed,
      });
    });

    setTaskFields(myTasks);
  };

  // for files attached
  const handleUpdateFileInfoFields = (files) => {
    let myFiles = [];
    files.forEach(({ _id, filename }) => {
      myFiles.push({
        uuid: uuidv4(),
        id: _id,
        filename,
        fileUrl: `${process.env.REACT_APP_BASE_URL}/files/download/${filename}`,
      });
    });
    setFilesInfo(myFiles);
  };

  const handleRemoveFileInfoFields = (uuid) => {
    const values = [...filesInfo];
    values.splice(
      values.findIndex((value) => value.uuid === uuid),
      1
    );
    setFilesInfo(values);
  };

  // For Document Specifications

  const handleUpdateDocSpecFields = (docs) => {
    let myDocs = [];
    docs.forEach(({ documentChangedFrom, documentChangedTo, file }) => {
      myDocs.push({
        id: uuidv4(),
        documentChangedFrom,
        documentChangedTo,
        file,
      });
    });
    setDocSpecFields(myDocs);
  };

  const handleChangeDocSpecInput = (id, event) => {
    const newInputFields = docSpecFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setDocSpecFields(newInputFields);
  };

  const handleAddDocSpecFields = () => {
    setDocSpecFields([
      ...docSpecFields,
      {
        id: uuidv4(),
        documentChangedFrom: "",
        documentChangedTo: "",
        file: { id: "", filename: "" },
      },
    ]);
  };

  const handleRemoveDocSpecFields = (id) => {
    const values = [...docSpecFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setDocSpecFields(values);
  };

  const handleOnchange = (val) => {
    setProductsAffected(val.split(","));
  };

  const dispatch = useDispatch();

  const requestDetails = useSelector((state) => state.requestDetails);
  const { loading, error, request } = requestDetails;

  const requestUpdate = useSelector((state) => state.requestUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = requestUpdate;

  const userListForEcr = useSelector((state) => state.userListForEcr);
  const {
    loading: loadingUserList,
    error: errorUserList,
    users,
    success: successUserListUpdate,
  } = userListForEcr;

  const groupList = useSelector((state) => state.groupList);

  const {
    loading: loadingGroupList,
    error: errorGroupList,
    groups,
    success: successGroupListUpdate,
  } = groupList;

  let userInitial = [];
  let usersList = [];
  if (users && users.length > 0) {
    usersList = users.map((u) => {
      return {
        value: u._id,
        label: u.name + "-" + u.email,
        option: "users",
      };
    });
    userInitial = ecrChampion;
  }

  let groupsList = [];
  if (groups && groups.length > 0) {
    groupsList = groups.map((u) => {
      return {
        value: u._id,
        label: u.name,
        option: "groups",
      };
    });
  }

  const productList = useSelector((state) => state.productList);
  const {
    loading: loadingProducts,
    error: errorProducts,
    products,
  } = productList;

  let productOptions = [];
  let productsInitial = [];
  if (products && products.length > 0) {
    productOptions = products.map((p) => {
      return {
        label: p.name,
        value: p._id,
        style: {
          color: "blue",
        },
      };
    });
    productsInitial = productsAffected;
  }

  // grouped Options
  const groupedOptions = [
    {
      label: "Groups",
      options: groupsList && groupsList.length > 0 ? groupsList : [],
    },
    {
      label: "Users",
      options: usersList,
    },
  ];

  const logChange = (val) => {
    setEcrChampion(val);
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      if (
        users &&
        groups &&
        products &&
        users.length == 0 &&
        groups.length == 0 &&
        products.length == 0
      ) {
        dispatch(listUsersForEcr());
        dispatch(listGroups("", "", false));
        dispatch(listProducts("", "", false));
      }
    }
    if (successUpdate) {
      dispatch({ type: REQUEST_UPDATE_RESET });
      dispatch({ type: REQUEST_DETAILS_RESET });
    } else {
      if (!request || !request.title || request._id !== requestId) {
        dispatch(listRequestDetails(requestId));
      } else {
        setTitle(request.title);
        setChangesRequired(request.changesRequired);
        setProblemStatement(request.problemStatement);
        setProductsAffected(request.productsAffected);
        setProgress(request.progress);
        if (request.tasksProgress.tasks.length > 0) {
          handleUpdateTaskFields(request.tasksProgress.tasks);
        }

        let comments = [];
        request.progress.steps.forEach((step) => {
          if (step.comments.length > 0) {
            step.comments.forEach((comment) => {
              let userLabel = "";
              if (usersList.length > 0) {
                let user = usersList.find((u) => u.value === comment.user);
                userLabel = user.label;
              }
              comments.push({
                userLabel: userLabel,
                name: step.name,
                ...comment,
              });
            });
          }
        });
        setComments(comments);
        setEhaComments(request.ehaComments);
        setSaComments(request.saComments);
        setCcComments(request.ccComments);
        setEngineeringValidationComments(request.engineeringValidationComments);
        setCreatedAt(convertISODate(request.createdAt));
        setEcrChampion(request.ecrChampion);
        setIsSubmitted(request.isSubmitted);
        if (request.filesAttached && request.filesAttached.length > 0) {
          handleUpdateFileInfoFields(request.filesAttached);
        }
        if (
          request.documentSpecifications &&
          request.documentSpecifications.length > 0
        ) {
          handleUpdateDocSpecFields(request.documentSpecifications);
        }
      }
    }
  }, [
    requestId,
    request,
    successUpdate,
    successUserListUpdate,
    successGroupListUpdate,
    userInfo,
  ]);

  const updateTaskCompleted = async(event, id) => {
    handleChangeTaskInput(id, event);
    await updateRequest({
        title,
        changesRequired,
        problemStatement,
        productsAffected,
        newComment,
        ehaComments,
        saComments,
        ccComments,
        engineeringValidationComments,
        ecrChampion,
        taskFields,
        docSpecFields,
        filesAttached: filesInfo.map((fl) => fl.id),
        isSubmitted,
    }, requestId);
  }

  const serverURL = process.env.NODE_ENV==='development' ? 'http://localhost/files/download/' : 'https://ec.unirac.com/api/files/download/';

  return (
    <div id="requestViewScreen">
      <button onClick={() => history.goBack()} className="btn btn-light my-3">
        Go Back
      </button>
      <FormContainer>
        <h1>ECR (ENGINEERING CHANGE REQUEST)</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form>
            <Row>
              <Col>
                <Form.Group
                  style={{
                    padding: "10px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                    color: "black",
                    fontSize: "1.2rem",
                  }}
                  controlId="ecrID"
                >
                  <Form.Label>ECR ID</Form.Label>
                  <Form.Control
                    style={{ color: "black", fontSize: "1rem" }}
                    readOnly
                    type="string"
                    // placeholder="Enter ECR ID"
                    value={request.ecrId}
                    // onChange={(e) => setCreatedAt(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  style={{
                    padding: "10px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                    color: "black",
                    fontSize: "1.2rem",
                  }}
                  controlId="createdAt"
                >
                  <Form.Label>Initiation Date</Form.Label>
                  <Form.Control
                    style={{ color: "black", fontSize: "1rem" }}
                    readOnly
                    type="string"
                    placeholder="Enter Initiation Date"
                    value={createdAt}
                    // onChange={(e) => setCreatedAt(e.target.value)}
                    disabled={!userInfo?.isAdmin}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  style={{
                    padding: "10px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                    color: "black",
                    fontSize: "1.2rem",
                  }}
                  controlId="title"
                >
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    style={{ color: "black", fontSize: "1rem" }}
                    as="textarea"
                    rows={2}
                    type="name"
                    placeholder="Enter Title"
                    value={title}
                    // onChange={(e) => setTitle(e.target.value)}
                    disabled={!userInfo?.isAdmin}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  style={{
                    padding: "10px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                    color: "black",
                    fontSize: "1.2rem",
                  }}
                  controlId="problemStatement"
                >
                  <Form.Label>
                    Problem Statement (Current Condition){" "}
                  </Form.Label>
                  <Form.Control
                    style={{ color: "black", fontSize: "1rem" }}
                    type="name"
                    as="textarea"
                    rows={3}
                    placeholder="Enter Problem Statement"
                    value={problemStatement}
                    // onChange={(e) => setProblemStatement(e.target.value)}
                    readOnly
                    disabled={!userInfo?.isAdmin}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  style={{
                    padding: "10px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                    color: "black",
                    fontSize: "1.2rem",
                  }}
                  controlId="changesRequired"
                >
                  <Form.Label>
                    Changes Required (Detailed Description){" "}
                  </Form.Label>
                  <Form.Control
                    style={{ color: "black", fontSize: "1rem" }}
                    type="name"
                    as="textarea"
                    rows={3}
                    placeholder="Enter Changes Required"
                    value={changesRequired}
                    // onChange={(e) => setChangesRequired(e.target.value)}
                    readOnly
                    disabled={!userInfo?.isAdmin}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  style={{
                    padding: "10px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                    color: "black",
                    fontSize: "1.2rem",
                  }}
                  controlId="productsAffected"
                >
                  <Form.Label style={{ color: "black", fontSize: "1.2rem" }}>
                    Products Affected
                  </Form.Label>
                  <MultiSelect
                    style={{ width: "100%" }}
                    defaultValue={productsInitial}
                    // onChange={handleOnchange}
                    options={productOptions}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  style={{
                    padding: "10px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                    color: "black",
                    fontSize: "1.2rem",
                  }}
                  controlId="ecrChampion"
                >
                  <Form.Label style={{ color: "black", fontSize: "1.2rem" }}>
                    ECR Champion
                  </Form.Label>
                  <MultiSelect
                    style={{ width: "100%" }}
                    name="ecrChampion"
                    defaultValue={userInitial}
                    // onChange={logChange}
                    options={usersList}
                    singleSelect={true}
                    // disabled={isSubmitted}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  className="formgroup"
                  controlId="ehaformBasicCheckbox"
                >
                  <div className="label-Tooltip">
                     <Form.Label style={{ color: "black", fontSize: "1.2rem" }}>
                      Environmental Health Assessment
                      <ToolTip description="Check the box and fill in the appropriate health assessments for the change." />
                    </Form.Label>
                  </div>
                  <Form.Control
                    style={{ color: "black", fontSize: "1rem" }}
                    type="name"
                    as="textarea"
                    rows={2}
                    placeholder="Enter Environmental Health Assessment"
                    value={ehaComments}
                    readOnly
                  >
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please enter EHA comments.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="formgroup"
                  controlId="saformBasicCheckbox"
                >
                  <div className="label-Tooltip">
                    <Form.Label style={{ color: "black", fontSize: "1.2rem" }}>
                      Safety Assessment
                      <ToolTip description="Check the box and fill in the appropriate safety assessments for the change." />
                    </Form.Label>
                  </div>
                  <Form.Control
                    style={{ color: "black", fontSize: "1rem" }}
                    type="name"
                    as="textarea"
                    rows={2}
                    placeholder="Enter Safety Assessment"
                    value={saComments}
                    readOnly
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please enter Safety Assessment comments.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  className="formgroup"
                  controlId="ccformBasicCheckbox"
                >
                  <div className="label-Tooltip">
                    <Form.Label style={{ color: "black", fontSize: "1.2rem" }}>
                      Certification Consideration
                      <ToolTip description="Check the box and fill in the appropriate considerations for the change. If unsure, talk to the certification engineer before submitting"/>
                    </Form.Label>
                  </div>
                  <Form.Control
                    style={{ color: "black", fontSize: "1rem" }}
                    type="name"
                    as="textarea"
                    rows={2}
                    placeholder="Enter Certification Considerations"
                    value={ccComments}
                    readOnly
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please enter Certification Consideration comments.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="formgroup"
                >
                  <Row className="align-items-center">
                    <Col>
                      <Form.Label className="additionalDetailFilesLabel">Additional Detail Files
                        <ToolTip description="Include redlined drawings/ documents if required" />
                      </Form.Label>
                      <h6>{`.doc & .xls files aren't supported`}</h6>
                    </Col>
                  </Row>
                  <Row style={{ textAlign: "center",marginRight:"-10px",marginLeft:"-10px" }}>
                    <Table bordered hover responsive className="table-sm">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>FILE NAME</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filesInfo && filesInfo.length > 0
                          ? filesInfo.map((inputField, index) => (
                              <tr key={inputField.id}>
                                <td>{index + 1}</td>
                                <td>
                                  <a
                                    href={`${serverURL}${inputField.filename}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download
                                  >{inputField.filename}</a>
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </Table>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  style={{
                    padding: "10px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                    color: "black",
                    fontSize: "1.2rem",
                  }}
                  controlId="newComment"
                >
                  <Form.Label>Add Comment</Form.Label>
                  <Form.Control
                    style={{ color: "black", fontSize: "1rem" }}
                    type="name"
                    as="textarea"
                    rows={2}
                    placeholder="Enter Comments"
                    value={newComment}
                    // onChange={(e) => setNewComment(e.target.value)}
                    disabled={!userInfo?.isAdmin}
                    readOnly
                  ></Form.Control>
                  {comments.map(
                    ({_id, commentedAt, comment, userLabel, action, name}) =>
                      <CommentBox
                        style={{ marginBottom: "10px" }}
                        key={_id}
                        time={commentedAt}
                        comment={comment}
                        user={userLabel}
                        action={action}
                        phase={name}
                      />
                  )}
                </Form.Group>
              </Col>
              <Col>
                  <Form.Group
                    className="formgroup"
                    controlId="evformBasicCheckbox"
                  >
                    <div className="label-Tooltip">
                      <Form.Label style={{ color: "black", fontSize: "1.2rem" }}>
                        Engineering Validation
                        <ToolTip description="Check the box and fill in the appropriate validations for the change. If unsure, talk to the engineer before submitting"/>
                      </Form.Label>
                    </div>
                    <Form.Control
                      style={{ color: "black", fontSize: "1rem" }}
                      type="name"
                      as="textarea"
                      rows={2}
                      placeholder="Enter Engineering Validation"
                      value={engineeringValidationComments}
                      disabled={!userInfo?.isAdmin}
                      readOnly
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please enter Engineering Validation comments.
                    </Form.Control.Feedback>
                  </Form.Group>
              </Col>
            </Row>
            {isSubmitted ? (
              <Form.Group
                style={{
                  padding: "10px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                  color: "black",
                  fontSize: "1.2rem",
                }}
              >
                <Row className="align-items-center">
                  <Col>
                    <h4>Task List</h4>
                  </Col>
                  <Col className="text-right">
                    <Button
                      className="my-3"
                      // onClick={handleAddTaskFields}
                      disabled={!userInfo?.isAdmin}
                    >
                      <i className="fas fa-plus"></i>Add New Task
                    </Button>
                  </Col>
                </Row>
                <Row style={{ textAlign: "center" }}>
                  <Table bordered hover responsive className="table-sm">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>TASK GROUP</th>
                        <th>TASK DETAIL</th>
                        <th>COMPLETED</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {taskFields.length > 0
                        ? taskFields.map((inputField, index) => (
                            <tr key={inputField.id}>
                              <td>{index + 1}</td>
                              <td>
                                <Creatable
                                  name="taskAssigned"
                                  classNamePrefix="select"
                                  name="color"
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  // onChange={(event) =>
                                  //   handleChangeTaskInput(inputField.id, {
                                  //     target: {
                                  //       name: "taskAssigned",
                                  //       value: event,
                                  //     },
                                  //   })
                                  // }
                                  isMulti
                                  options={groupedOptions}
                                  defaultValue={inputField.taskAssigned}
                                  // isDisabled={!userInfo?.isAdmin}
                                  isDisabled
                                />
                              </td>
                              <td>
                                <TextField
                                  style={{ width: "100%" }}
                                  name="taskDetails"
                                  label="Task Details"
                                  maxRows={3}
                                  multiline
                                  id="standard-basic"
                                  value={inputField.taskDetails}
                                  // onChange={(event) =>
                                  //   handleChangeTaskInput(inputField.id, event)
                                  // }
                                  // disabled={!userInfo?.isAdmin}
                                  disabled
                                />
                              </td>
                              <td>
                                <Checkbox
                                  id="completed"
                                  color="primary"
                                  checked={inputField.completed}
                                  onChange={(event) => updateTaskCompleted(event, inputField.id)}
                                  disabled={!((inputField.taskAssigned.some((taskHolder)=>taskHolder.label===(userInfo.name+"-"+userInfo.email))) || (userInfo?.isAdmin))}
                                />
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  className="btn-sm mx-1 my-1"
                                  // onClick={() =>
                                  //   handleRemoveTaskFields(inputField.id)
                                  // }
                                  disabled
                                >
                                  <i className="fas fa-trash"></i>
                                </Button>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </Table>
                </Row>
              </Form.Group>
            ) : null}
            {isSubmitted ? (
              <Form.Group
                style={{
                  padding: "10px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                  color: "black",
                  fontSize: "1.2rem",
                }}
              >
                <Row className="align-items-center">
                  <Col>
                    <Tooltip
                      color="#fff"
                      background="black"
                      content="Specification (Drawing, COTS, Kit, Installation manual, Design & Engineering Guide): New number or revision change e.g. From None - New document To M12125 e.g. From P28412345 Rev A To: P28412345 Rev B"
                    >
                      <h4>Documentation (Specification) Changes</h4>
                    </Tooltip>
                  </Col>
                  <Col className="text-right">
                    <Button
                      className="my-3"
                      // onClick={handleAddDocSpecFields}
                      disabled={!userInfo?.isAdmin}
                    >
                      <i className="fas fa-plus"></i>Add New Specification
                    </Button>
                  </Col>
                </Row>
                <Row style={{ textAlign: "center" }}>
                  <Table bordered hover responsive className="table-sm">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>DOCUMENT CHANGED FROM</th>
                        <th>DOCUMENT CHANGED TO</th>
                        <th>ATTACH FILE</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {docSpecFields && docSpecFields.length > 0
                        ? docSpecFields.map((inputField, index) => (
                            <tr key={inputField.id}>
                              <td>{index + 1}</td>
                              <td>
                                <TextField
                                  style={{ width: "100%" }}
                                  name="documentChangedFrom"
                                  label="Document Changed (From)"
                                  maxRows={3}
                                  multiline
                                  id="standard-basic"
                                  value={inputField.documentChangedFrom}
                                  onChange={(event) =>
                                    handleChangeDocSpecInput(
                                      inputField.id,
                                      event
                                    )
                                  }
                                  // disabled={!userInfo?.isAdmin}
                                  disabled
                                />{" "}
                              </td>
                              <td>
                                <TextField
                                  style={{ width: "100%" }}
                                  name="documentChangedTo"
                                  label="Document Changed (To)"
                                  maxRows={3}
                                  multiline
                                  id="standard-basic"
                                  value={inputField.documentChangedTo}
                                  onChange={(event) =>
                                    handleChangeDocSpecInput(
                                      inputField.id,
                                      event
                                    )
                                  }
                                  // disabled={!userInfo?.isAdmin}
                                  disabled
                                />
                              </td>
                              <td>
                                <Form.Group controlId="file">
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter File url"
                                    value={inputField.file?.filename}
                                    disabled={!userInfo?.isAdmin}
                                    readOnly
                                  ></Form.Control>
                                  {/* <Form.File
                                    id="file"
                                    label="Choose File"
                                    custom
                                    onChange={(e) =>
                                      uploadFileHandlerCustom(e, inputField.id)
                                    }
                                    disabled={!userInfo?.isAdmin}
                                  ></Form.File> */}
                                  {uploading && <Loader />}
                                </Form.Group>
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  className="btn-sm mx-1 my-1"
                                  // onClick={() =>
                                  //   handleRemoveDocSpecFields(inputField.id)
                                  // }
                                  disabled={!userInfo?.isAdmin}
                                >
                                  <i className="fas fa-trash"></i>
                                </Button>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </Table>
                </Row>
              </Form.Group>
            ) : null}
            {/* {(!isSubmitted || (isSubmitted && userInfo?.isAdmin)) &&
            userInfo?.isAdmin ? (
              <Button
                variant="primary"
                style={{ margin: "10px" }}
                onClick={saveHandler}
              >
                Save
              </Button>
            ) : null}
            {!isSubmitted && userInfo?.isAdmin ? (
              <Button type="submit" variant="primary">
                Submit
              </Button>
            ) : null}
            {isSubmitted && userInfo?.isAdmin ? (
              <Button variant="primary" onClick={assignHandler}>
                Assign
              </Button>
            ) : null} */}
          </Form>
        )}
      </FormContainer>
    </div>
  );
};

export default RequestViewScreen;
