import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Header, Footer, ProtectedRoute } from "./components";
import { EmailVerification, ForgotPasswordScreen, RegisterScreen, ResetPasswordScreen, LoginScreen } from "./screens";
import { PROTECTED_ROUTES } from "./constants/constants";
import { useSelector } from "react-redux";

const App = () => {
  const { userInfo } = useSelector(state => state.userLogin);
  return (
    <Router>
      <Header />
      <main className="py-3">
        <div className="px-3">
          <Route path="/login" component={LoginScreen} />
          <Route path="/register" component={RegisterScreen} />
          <Route path="/forgot" component={ForgotPasswordScreen} />
          <Route path="/reset" component={ResetPasswordScreen} />
          <Route path="/verifyEmail" component={EmailVerification} />
          {PROTECTED_ROUTES.map(({ path, component, ...rest }, index) => <ProtectedRoute key={index} path={path} component={component} userInfo={userInfo} {...rest} />)}
        </div>
      </main>
      <Footer />
    </Router>
  );
};

export default App;