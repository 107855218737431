/* Used for Email Boxes */
import React from "react";
import "./Chip.css";

const Chip = ({ item, onEdit, onDelete }) => (
  <div className="tag-item">
    <span onClick={() => onEdit(item)}>{item}</span>
    <button className="button" onClick={() => onDelete(item)}>
      &times;
    </button>
  </div>
);

export default Chip;
