import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { resetPassword } from "../actions/userActions";
import FormContainer from "../components/FormContainer";
import Message from "../components/Message";

const ResetPasswordScreen = () => {
  const [password, setPassword] = useState("");
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (userInfo) {
      history.push("/");
    }
  }, []);

  const token = useLocation().pathname.split("/")[2];

  const submitHandler = (e) => {
    e.preventDefault();

    resetPassword(password, token)
      .then(({ data }) => {
        setResponse(data);
        setPassword("");
        history.push("/login");
      })
      .catch(({ message }) => setError(message));
  };

  return (
    <FormContainer>
      <h1>Reset Password</h1>
      {response && !error && <Message variant="success">{response}</Message>}
      {error && !response && <Message variant="danger">{error}</Message>}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="password">
          <Form.Label>Enter your new password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Button type="submit" variant="primary">
          Reset Password
        </Button>
      </Form>
      <Row className="py-3">
        <Col>
          New Customer? <Link to={"/register"}>Register</Link>
        </Col>
        <Col>
          Have an Account? <Link to={"/login"}>Login</Link>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default ResetPasswordScreen;
