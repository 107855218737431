export default {
    app: {
        loading: false,
        message: '',
        error: '',
    },
    dispatch: () => { },
    history: {},
    userInfo: {},
    requestDetails: { request: { filesAttached: [] } },
    requestList: {},
    userDetails: {user:{}},
    userList: {},
    groupDetails: {},
    groupList: {},
    productDetails: {},
    productList: {},
}