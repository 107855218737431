import React, { useEffect, useMemo } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import Paginate from '../components/Paginate';
import { listGroups, deleteGroup, createGroup } from '../actions/groupActions';
import { GROUP_CREATE_RESET } from '../constants/groupConstants.js';
import DataTable from 'react-data-table-component';
import FilterComponent from '../components/FilterComponent';

const GroupListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1;

  const dispatch = useDispatch();

  const groupList = useSelector((state) => state.groupList);
  const { loading, error, groups, page, pages } = groupList;

  const groupDelete = useSelector((state) => state.groupDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = groupDelete;

  const groupCreate = useSelector((state) => state.groupCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    group: createdGroup,
  } = groupCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: GROUP_CREATE_RESET });
    if (successCreate && userInfo.isAdmin) {
      history.push(`/admin/group/${createdGroup._id}/edit`);
    } else {
      dispatch(listGroups('', pageNumber));
    }
  }, [
    userInfo,
    successDelete,
    successCreate,
    createdGroup,
    pageNumber,
  ]);

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteGroup(id));
    }
  };

  const createGroupHandler = () => {
    dispatch(createGroup());
  };

  return (
    <>
      <Row className="align-items-center">
        <Col>
          <h1>Groups</h1>
        </Col>
        <Col className="text-right">
          <Button className="my-3" onClick={createGroupHandler}>
            <i className="fas fa-plus"></i> Add New Group
          </Button>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant="danger">{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>NAME</th>
                <th>USERS</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {groups.map((group) => (
                <tr key={group._id}>
                  <td>{group.name}</td>
                  <td>{group.users.map((u) => u.name + ' || ')}</td>
                  <td>
                    <LinkContainer to={`/admin/group/${group._id}/edit`}>
                      <Button variant="light" className="btn-sm">
                        <i className="fas fa-edit"></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteHandler(group._id)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate pages={pages} page={page} isAdmin={true} />
        </>
      )}
    </>
  );
};

export default GroupListScreen;
