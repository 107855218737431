import React, { useState, useEffect, useMemo } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import PaginateRequest from '../components/PaginateRequest';
import {
  listMyRequestsAsInitiator,
  deleteRequest,
} from '../actions/requestActions';
import { REQUEST_CREATE_RESET } from '../constants/requestConstants';
import { sortingFunctionForDate, sortingFunctionForIsSubmitted } from '../utils/sortingFunctions';
import Paginate from '../components/Paginate';
import FilterComponent from '../components/FilterComponent';
import DataTable from 'react-data-table-component';
import { REQUEST_PHASES } from '../constants/RequestPhases';
// import CustomMaterialPagination from '../components/CustomMaterialPagination';

const RequestListInitiatorScreen = ({ history, match }) => {
  // const pageNumber = match.params.pageNumber || 1;
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();

  const requestListMyInitiator = useSelector(
    (state) => state.requestListMyInitiator
  );
  const { loading, error, requests, page, pages, totalRows } = requestListMyInitiator;

  const requestDelete = useSelector((state) => state.requestDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = requestDelete;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) dispatch(listMyRequestsAsInitiator('', pageNumber, pageSize));
  }, [
    userInfo,
    successDelete,
    pageNumber,
    pageSize
  ]);

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteRequest(id));
    }
  };

  const [filterText, setFilterText] = useState("");
  // const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const columns = [
    {
      name: '',
      selector: row => (
        <LinkContainer to={`/request/${row._id}/view`}>
          <Button variant="light" className="btn-sm">
            <i className="fas fa-external-link-square-alt"></i>
          </Button>
        </LinkContainer>
      ),
    },
    {
      name: 'ID',
      selector: row => row.ecrId,
      sortable: true,
      wrap: true,
    },
    {
      name: 'TITLE',
      selector: row => row.title,
      sortable: true,
      wrap: true,
    },
    {
      name: 'INITIATED BY',
      selector: row => row.initiatedBy,
      sortable: true,
      wrap: true,
    },
    {
      name: 'ECR CHAMPION',
      selector: row => row.ecrChampion,
      sortable: true,
      wrap: true,
    },
    {
      name: 'PRODUCTS AFFECTED',
      selector: row => row.productsAffected,
      sortable: true,
      wrap: true,
    },
    {
      name: 'IS SUBMITTED',
      selector: row => row.isSubmitted ? (
        <i
          className="fas fa-check"
          style={{ color: 'green' }}
        ></i>
      ) : (
        <i className="fas fa-times" style={{ color: 'red' }}></i>
      ),
      sortFunction: sortingFunctionForIsSubmitted,
    },
    {
      name: 'STAGE',
      selector: row => REQUEST_PHASES[row.currentStep],
      sortable: true,
      sortFunction: (a, b) => a.currentStep - b.currentStep,
      wrap: true,
    },
    {
      name: 'CREATED AT',
      selector: row => row.createdAt,
      sortFunction: (a, b) => sortingFunctionForDate(a.createdAt, b.createdAt),
      wrap: true,
    },
    {
      name: 'UPDATED AT',
      selector: row => row.updatedAt,
      sortFunction: (a, b) => sortingFunctionForDate(a.createdAt, b.createdAt),
      wrap: true,
    },
    {
      name: 'ACTIONS',
      selector: row => (
        <>
          <LinkContainer to={`/request/${row._id}/edit`}>
            <Button variant="light" className="btn-sm">
              <i className="fas fa-edit"></i>
            </Button>
          </LinkContainer>
          <Button
            variant="danger"
            className="btn-sm"
            onClick={() => deleteHandler(row._id)}
          >
            <i className="fas fa-trash"></i>
          </Button>
        </>
      ),
      wrap: true,
    },
  ];

  let data = []
  if (requests) {
    data = requests.map(({ _id, ecrId, title, initiatedBy, ecrChampion, productsAffected, isSubmitted, progress: { currentStep }, createdAt, updatedAt }) => {
      return {
        _id,
        ecrId: ecrId ?? '',
        title,
        initiatedBy: initiatedBy.name,
        ecrChampion: ecrChampion.name,
        productsAffected: productsAffected.length > 0 ? productsAffected.map(p => p.name).join("|| ") : '',
        isSubmitted,
        currentStep,
        createdAt: new Date(createdAt).toLocaleDateString('en-US'),
        updatedAt: new Date(updatedAt).toLocaleDateString('en-US'),
      }
    })
  }

  const filteredItems = data.filter(
    ({ ecrId, initiatedBy, isSubmitted, productsAffected, title, updatedAt, _id }) =>
      [ecrId, title, initiatedBy, productsAffected, isSubmitted, updatedAt].join(',').toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        // setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText]);

  return (
    <>
      <Row className="align-items-center">
        <Col>
          <h3>
            MY CHANGE REQUESTS <br></br>
          </h3>
          <h6>(As Initiator)</h6>
        </Col>
      </Row>
      {loadingDelete ? <Loader /> : null}
      {errorDelete ? <Message variant="danger">{errorDelete}</Message> : null}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <DataTable
            columns={columns}
            data={filteredItems}
            defaultSortFieldId={2}
            defaultSortAsc={false}
            highlightOnHover
            striped
            responsive
            subHeader
            subHeaderComponent={subHeaderComponent}
            pagination
          // paginationServer
          // paginationPerPage={pageSize}
          // paginationTotalRows = {totalRows}
          // onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
          //   setPageSize(currentRowsPerPage)
          //   // setPageNumber(currentPage)
          //   console.log('onChangeRowsPerPage', 'currentRowsPerPage', currentRowsPerPage, 'currentPage', currentPage, 'pages', pages, 'page', page)
          // }}
          // onChangePage={(currentPage, totalRowsPerPage) => {
          //   setPageSize(10);
          //   setPageNumber(currentPage);
          //   console.log('onChangePage', 'currentPage - ', currentPage, 'totalRowsPerPage - ', totalRowsPerPage, 'pages - ', pages, 'page - ', page)
          // }}
          />
          <br />
        </>
      )}
    </>
  );
};

export default RequestListInitiatorScreen;
