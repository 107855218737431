import React from 'react';
import { Card } from 'react-bootstrap';

const CommentBox = ({ time, comment, user, action, phase }) => {
  return (
    <>
      <Card
        md={6}
        style={{ borderStyle: 'groove',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px', margin:'10px', color: 'black', fontSize: '0.7rem' }}
      >
        <Card.Body>
          <Card.Title>{comment}</Card.Title>
          <Card.Text>
            Commented By: {user} &#8226; Commented At: {time} &#8226; Phase :
            {phase} &#8268; Action :{action}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default CommentBox;
