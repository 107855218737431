import React, { useEffect, useState, useMemo } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import Tooltip from "react-tooltip-lite";
import {
  approveRequest,
  commentRequest,
  deleteRequest,
  listRequests,
  withdrawRequest,
} from "../actions/requestActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import ModalForm from "../components/ModalForm";
import RequestPhasesAdmin from "../components/RequestPhasesAdmin";
import {
  REQUEST_APPROVE_RESET,
  REQUEST_WITHDRAW_RESET,
} from "../constants/requestConstants";
import { sortingFunctionForDate, sortingFunctionForIsSubmitted } from "../utils/sortingFunctions";
import DataTable from 'react-data-table-component';
import FilterComponent from '../components/FilterComponent';
import { REQUEST_PHASES, RequestPhases } from "../constants/RequestPhases";

const RequestListAdminScreen = ({ history, match }) => {
  const screenNameId = RequestPhases[history.location.pathname.split('/')[3]];
  const [screenNameNumber, setScreenNameNumber] = useState(screenNameId);
  const [requestId, setRequestId] = useState("");
  const [withdrawModalShow, setWithdrawModalShow] = useState(false);
  const [approveModalShow, setApproveModalShow] = useState(false);
  const [commentsModalShow, setCommentsModalShow] = useState(false);
  const [withdrawNote, setWithdrawNote] = useState("");
  // commentsNote is not saved to the commentsArray. Will be fixed later.
  const [commentNote, setCommentNote] = useState("");
  const [approveNote, setApproveNote] = useState("");

  const pageNumber = match.params.pageNumber || 1;
  const dispatch = useDispatch();

  const requestList = useSelector((state) => state.requestList);
  const { loading, error } = requestList;
  const requests = requestList?.requests?.filter(
    (r) => r.progress.currentStep === screenNameNumber
  );
  const requestDelete = useSelector((state) => state.requestDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = requestDelete;

  const { userInfo: { isAdmin } } = useSelector((state) => state.userLogin);

  const { success: successRequestWithdraw } = useSelector((state) => state.requestWithdraw);
  const { success: successRequestApprove } = useSelector((state) => state.requestApprove);
  const { success: successRequestComment } = useSelector((state) => state.requestComment);

  useEffect(() => setScreenNameNumber(screenNameId), [screenNameId])

  useEffect(() => {
    if (successRequestApprove) {
      dispatch({ type: REQUEST_APPROVE_RESET });
    } else if (successRequestWithdraw) {
      dispatch({ type: REQUEST_WITHDRAW_RESET });
    } else if (successRequestComment) {
      dispatch({ type: REQUEST_WITHDRAW_RESET });
    } else dispatch(listRequests());
  }, [
    successDelete,
    successRequestApprove,
    successRequestComment,
    successRequestWithdraw,
  ]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure")) {
      dispatch(deleteRequest(id));
    }
  };

  const withdrawHandler = (id) => {
    setRequestId(id);
    setWithdrawModalShow(true);
  };

  const approveHandler = (id) => {
    setRequestId(id);
    setApproveModalShow(true);
  };

  const commentsHandler = (id) => {
    setRequestId(id);
    setCommentsModalShow(true);
  };

  const columns = [
    {
      name: '',
      selector: row => (
        <LinkContainer to={`/request/${row._id}/view`}>
          <Button variant="light" className="btn-sm">
            <i className="fas fa-external-link-square-alt"></i>
          </Button>
        </LinkContainer>
      ),
    },
    {
      name: 'ID',
      selector: row => row.ecrId,
      sortable: true,
      wrap: true,
    },
    {
      name: 'TITLE',
      selector: row => row.title,
      sortable: true,
      wrap: true,
    },
    {
      name: 'INITIATED BY',
      selector: row => row.initiatedBy,
      sortable: true,
      wrap: true,
    },
    {
      name: 'ECR CHAMPION',
      selector: row => row.ecrChampion,
      sortable: true,
      wrap: true,
    },
    {
      name: 'PRODUCTS AFFECTED',
      selector: row => row.productsAffected,
      sortable: true,
      wrap: true,
    },
    {
      name: 'IS SUBMITTED',
      selector: row => row.isSubmitted ?
        <i className="fas fa-check" style={{ color: 'green' }}></i> :
        <i className="fas fa-times" style={{ color: 'red' }}></i>,
      sortFunction: sortingFunctionForIsSubmitted,
    },
    {
      name: 'STAGE',
      selector: row => REQUEST_PHASES[row.currentStep],
      sortable: true,
      sortFunction: (a, b) => a.currentStep - b.currentStep,
      wrap: true,
    },
    {
      name: 'CREATED AT',
      selector: row => row.createdAt,
      sortFunction: (a, b) => sortingFunctionForDate(a.createdAt, b.createdAt),
      wrap: true,
    },
    {
      name: 'UPDATED AT',
      selector: row => row.updatedAt,
      sortFunction: (a, b) => sortingFunctionForDate(a.updatedAt, b.updatedAt),
      wrap: true,
    },
    {
      name: 'Tasks Progress',
      selector: row => row.tasksProgress,
      sortable: true,
      wrap: true,
    },
    isAdmin && {
      name: 'ACTIONS',
      selector: row => (
        <>
          <LinkContainer to={`/request/${row._id}/edit`}>
            <Button variant="light" className="btn-sm">
              <i className="fas fa-edit"></i>
            </Button>
          </LinkContainer>
          <Button
            variant="danger"
            className="btn-sm"
            onClick={() => deleteHandler(row._id)}
          >
            <i className="fas fa-trash"></i>
          </Button>
        </>
      ),
      wrap: true,
    },
    isAdmin && {
      name: 'ADMIN ACTIONS',
      selector: row => (
        <>
          <Tooltip
            color="#fff"
            background="black"
            content="Give initiation approval to the request"
          >
            <Button
              variant="light"
              className="btn-sm mx-1 my-1"
              onClick={() => approveHandler(row._id)}
            >
              <i className="far fa-thumbs-up"></i>
            </Button>
          </Tooltip>
          <Tooltip
            color="#fff"
            background="black"
            content="Withdraw the request"
          >
            <Button
              variant="light"
              className="btn-sm mx-1 my-1"
              onClick={() => withdrawHandler(row._id)}
            >
              <i className="far fa-thumbs-down"></i>
            </Button>
          </Tooltip>
          <Tooltip
            color="#fff"
            background="black"
            content="Post a Comment"
          >
            <Button
              variant="light"
              className="btn-sm mx-1 my-1"
              onClick={() => commentsHandler(row._id)}
            >
              <i className="far fa-comments"></i>
            </Button>
          </Tooltip>
        </>
      ),
      wrap: true,
    }
  ];

  let data = []
  if (requests) {
    data = requests.map(({ _id, ecrId, title, initiatedBy, ecrChampion, progress: { currentStep }, productsAffected, isSubmitted, createdAt, updatedAt, tasksProgress: { tasks, tasksCompleted } }) => {
      return {
        _id,
        ecrId: ecrId ? ecrId : '',
        title: title.trim(),
        initiatedBy: initiatedBy?.name,
        ecrChampion: ecrChampion?.name,
        currentStep,
        productsAffected: productsAffected.length > 0 ? productsAffected.map(p => p.name).join("|| ") : '',
        isSubmitted,
        createdAt: new Date(createdAt).toLocaleDateString('en-US'),
        updatedAt: new Date(updatedAt).toLocaleDateString('en-US'),
        tasksProgress: `${tasksCompleted}/${tasks.length}`,
      }
    })
  }

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );

  const filteredItems = data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col>
          <h3>
            CHANGE REQUESTS RECEIVED<br></br>
            <span><h6>(As Admin)</h6></span>
          </h3>
        </Col>
        <Col className="text-right"></Col>
      </Row>
      <RequestPhasesAdmin className="my-1"></RequestPhasesAdmin>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <DataTable
            columns={columns}
            data={filteredItems}
            defaultSortFieldId={2}
            defaultSortAsc={false}
            highlightOnHover
            striped
            responsive
            subHeader
            subHeaderComponent={subHeaderComponent}
            pagination
          />
          <Modal
            show={withdrawModalShow}
            onHide={() => setWithdrawModalShow(false)}
          >
            <Modal.Dialog>
              <Modal.Header closeButton>
                <Modal.Title>Withdraw the Request ?</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <ModalForm
                  changeInput={(withdrawNote) => setWithdrawNote(withdrawNote)}
                />
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setWithdrawModalShow(false)}
                >
                  Close
                </Button>
                <Button
                  variant="danger"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      withdrawRequest({
                        _id: requestId,
                        comment: withdrawNote,
                        action: "Withdraw",
                      })
                    );
                    setWithdrawModalShow(false);
                  }}
                >
                  Withdraw
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </Modal>
          <Modal
            show={approveModalShow}
            onHide={() => setApproveModalShow(false)}
          >
            <Modal.Dialog>
              <Modal.Header closeButton>
                <Modal.Title>
                  Provide Initiation Approval to the Request ?
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <ModalForm
                  changeInput={(approveNote) => setApproveNote(approveNote)}
                />
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setApproveModalShow(false)}
                >
                  Close
                </Button>
                <Button
                  variant="success"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      approveRequest({
                        _id: requestId,
                        comment: approveNote,
                        action: "Approve",
                      })
                    );
                    setApproveModalShow(false);
                  }}
                >
                  Approve
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </Modal>
          <Modal
            show={commentsModalShow}
            onHide={() => setCommentsModalShow(false)}
          >
            <Modal.Dialog>
              <Modal.Header closeButton>
                <Modal.Title>Add Comments for the Request ?</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <ModalForm
                  changeInput={(commentNote) => setCommentNote(commentNote)}
                />
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setCommentsModalShow(false)}
                >
                  Close
                </Button>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      commentRequest({
                        _id: requestId,
                        comment: commentRequest,
                        action: "Further Comments",
                      })
                    );
                    setCommentsModalShow(false);
                  }}
                >
                  Send
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </Modal>
        </>
      )}
    </Container>
  );
};

export default RequestListAdminScreen;