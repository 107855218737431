export const REQUEST_LIST_REQUEST = 'REQUEST_LIST_REQUEST';
export const REQUEST_LIST_SUCCESS = 'REQUEST_LIST_SUCCESS';
export const REQUEST_LIST_FAIL = 'REQUEST_LIST_FAIL';

export const REQUEST_DETAILS_REQUEST = 'REQUEST_DETAILS_REQUEST';
export const REQUEST_DETAILS_SUCCESS = 'REQUEST_DETAILS_SUCCESS';
export const REQUEST_DETAILS_FAIL = 'REQUEST_DETAILS_FAIL';
export const REQUEST_DETAILS_RESET = 'REQUEST_DETAILS_RESET';

export const REQUEST_DELETE_REQUEST = 'REQUEST_DELETE_REQUEST';
export const REQUEST_DELETE_SUCCESS = 'REQUEST_DELETE_SUCCESS';
export const REQUEST_DELETE_FAIL = 'REQUEST_DELETE_FAIL';

export const REQUEST_CREATE_REQUEST = 'REQUEST_CREATE_REQUEST';
export const REQUEST_CREATE_SUCCESS = 'REQUEST_CREATE_SUCCESS';
export const REQUEST_CREATE_FAIL = 'REQUEST_CREATE_FAIL';
export const REQUEST_CREATE_RESET = 'REQUEST_CREATE_RESET';

export const REQUEST_UPDATE_REQUEST = 'REQUEST_UPDATE_REQUEST';
export const REQUEST_UPDATE_SUCCESS = 'REQUEST_UPDATE_SUCCESS';
export const REQUEST_UPDATE_FAIL = 'REQUEST_UPDATE_FAIL';
export const REQUEST_UPDATE_RESET = 'REQUEST_UPDATE_RESET';

export const REQUEST_SEND_MAIL_REQUEST = 'REQUEST_SEND_MAIL_REQUEST';
export const REQUEST_SEND_MAIL_SUCCESS = 'REQUEST_SEND_MAIL_SUCCESS';
export const REQUEST_SEND_MAIL_FAIL = 'REQUEST_SEND_MAIL_FAIL';
export const REQUEST_SEND_MAIL_RESET = 'REQUEST_SEND_MAIL_RESET';

export const REQUEST_SEND_ACTION_MAIL_REQUEST = 'REQUEST_SEND_ACTION_MAIL_REQUEST';
export const REQUEST_SEND_ACTION_MAIL_SUCCESS = 'REQUEST_SEND_ACTION_MAIL_SUCCESS';
export const REQUEST_SEND_ACTION_MAIL_FAIL = 'REQUEST_SEND_ACTION_MAIL_FAIL';
export const REQUEST_SEND_ACTION_MAIL_RESET = 'REQUEST_SEND_ACTION_MAIL_RESET';

export const REQUEST_LIST_MY_REQUEST_INITIATOR =
  'REQUEST_LIST_MY_REQUEST_INITIATOR';
export const REQUEST_LIST_MY_SUCCESS_INITIATOR =
  'REQUEST_LIST_MY_SUCCESS_INITIATOR';
export const REQUEST_LIST_MY_FAIL_INITIATOR = 'REQUEST_LIST_MY_FAIL_INITIATOR';
export const REQUEST_LIST_MY_RESET_INITIATOR =
  'REQUEST_LIST_MY_RESET_INITIATOR';

export const REQUEST_LIST_MY_REQUEST_CHAMPION =
  'REQUEST_LIST_MY_REQUEST_CHAMPION';
export const REQUEST_LIST_MY_SUCCESS_CHAMPION =
  'REQUEST_LIST_MY_SUCCESS_CHAMPION';
export const REQUEST_LIST_MY_FAIL_CHAMPION = 'REQUEST_LIST_MY_FAIL_CHAMPION';
export const REQUEST_LIST_MY_RESET_CHAMPION = 'REQUEST_LIST_MY_RESET_CHAMPION';

export const REQUEST_GET_REQUEST_COUNT = 'REQUEST_GET_REQUEST_COUNT';
export const REQUEST_GET_REQUEST_COUNT_SUCCESS = 'REQUEST_GET_REQUEST_COUNT_SUCCESS';
export const REQUEST_GET_REQUEST_COUNT_FAIL = 'REQUEST_GET_REQUEST_COUNT_FAIL';

export const REQUEST_LIST_MY_REQUEST_USER =
  'REQUEST_LIST_MY_REQUEST_USER';
export const REQUEST_LIST_MY_SUCCESS_USER =
  'REQUEST_LIST_MY_SUCCESS_USER';
export const REQUEST_LIST_MY_FAIL_USER = 'REQUEST_LIST_MY_FAIL_USER';
export const REQUEST_LIST_MY_RESET_USER =
  'REQUEST_LIST_MY_RESET_USER';


export const REQUEST_LIST_MY_REQUEST_TASKHOLDER =
  'REQUEST_LIST_MY_REQUEST_TASKHOLDER';
export const REQUEST_LIST_MY_SUCCESS_TASKHOLDER =
  'REQUEST_LIST_MY_SUCCESS_TASKHOLDER';
export const REQUEST_LIST_MY_FAIL_TASKHOLDER = 'REQUEST_LIST_MY_FAIL_TASKHOLDER';
export const REQUEST_LIST_MY_RESET_TASKHOLDER =
  'REQUEST_LIST_MY_RESET_TASKHOLDER';


export const REQUEST_APPROVE_REQUEST = 'REQUEST_APPROVE_REQUEST';
export const REQUEST_APPROVE_SUCCESS = 'REQUEST_APPROVE_SUCCESS';
export const REQUEST_APPROVE_FAIL = 'REQUEST_APPROVE_FAIL';
export const REQUEST_APPROVE_RESET = 'REQUEST_APPROVE_RESET';

export const REQUEST_WITHDRAW_REQUEST = 'REQUEST_WITHDRAW_REQUEST';
export const REQUEST_WITHDRAW_SUCCESS = 'REQUEST_WITHDRAW_SUCCESS';
export const REQUEST_WITHDRAW_FAIL = 'REQUEST_WITHDRAW_FAIL';
export const REQUEST_WITHDRAW_RESET = 'REQUEST_WITHDRAW_RESET';

export const REQUEST_COMMENTS_REQUEST = 'REQUEST_COMMENTS_REQUEST';
export const REQUEST_COMMENTS_SUCCESS = 'REQUEST_COMMENTS_SUCCESS';
export const REQUEST_COMMENTS_FAIL = 'REQUEST_COMMENTS_FAIL';
export const REQUEST_COMMENTS_RESET = 'REQUEST_COMMENTS_RESET';
