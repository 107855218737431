import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { activateAccount, sendVerifyEmailAction } from "../actions/userActions";
import Message from "../components/Message";

const EmailVerificationScreen = () => {
  const { userInfo } = useSelector(state => state.userLogin);
  const history = useHistory();
  const [message, setMessage] = useState('');
  const token = history.location.pathname.split("/")[2];

  useEffect(async () => {
    if (token && !userInfo?.active)
      setMessage(await activateAccount(token));
    else if (!userInfo?.email)
      history.push('/login');
    else if (userInfo?.active)
      history.push("/");
    else
      setMessage(await sendVerifyEmailAction());
  }, [userInfo]);

  const resendEmail = async () => {
    const message = await sendVerifyEmailAction();
    setMessage(message);
  }

  return (
    <Container fluid>
      <div className="align-items-center justify-content-center text-center py-5">
        <h2>Verify your email address</h2>
        <p>We have sent a verification link to <span className="text-dark text-uppercase font-weight-bold">{userInfo?.email}</span></p>
        <h4>Click on the link to complete the verification process</h4>
        <p>You might need to <strong>check your spam folder as well</strong></p>
        <Button className="my-3" onClick={resendEmail}>Resend email</Button>
        {message ? <Message variant={'success'}>{message}</Message> : null}
      </div>
    </Container>
  )
};

export default EmailVerificationScreen;
