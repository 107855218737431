import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequestsCountByStep } from "../actions/requestActions";
import { Link } from "react-router-dom";
import { Row, Col, Button, Container } from 'react-bootstrap';

const HomeScreen = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  useEffect(() => {
    if (userInfo)
      dispatch(getRequestsCountByStep());
  }, [userInfo]);
  const { requestsCount } = useSelector(state => state.getRequestCount);

  const requestsInfo = [
    {
      name: `All Open ECR's`,
      link: "/requestlistAsUser",
    },
    {
      name: `Initial Approval`,
      link: "/admin/requestlist/initiated",
    },
    {
      name: `Implementation task list`,
      link: "/admin/requestlist/tasklist",
    },
    {
      name: `Cert.Approval`,
      link: "/admin/requestlist/certification",
    },
    {
      name: `Implementation Approval`,
      link: "/admin/requestlist/implementation",
    },
    {
      name: `Post Implementation (Closed ECR's)`,
      link: "/admin/requestlist/postimplement",
    },
    {
      name: `Withdrawn ECR's`,
      link: "/admin/requestlist/withdrawn",
    },
  ];

  // [open_ecrs, intial approval, implementation_task_list, certification, implementation approval, closed_ecrs]

  const today = new Date();
  const currentDayOfWeek = today.getDay();
  const daysUntilNextMonday = (1 + 7 - currentDayOfWeek) % 7;
  const daysUntilLastMonday = (6 + currentDayOfWeek) % 7;
  const nextMonday = new Date(today.getFullYear(), today.getMonth(), today.getDate() + daysUntilNextMonday);;
  const lastMonday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - daysUntilLastMonday);;

  return (
    <Container>
      <Row className="my-3">
        <Col>
          <h2>ECR Dashboard</h2>
        </Col>
        <Col className="text-right">
          <a href="/assets/ECR - FAQ's_Unirac.pdf" target="_blank" rel="noopener noreferrer download">
            <Button>
              GET TO KNOW THE PORTAL
            </Button>
          </a>
        </Col>
      </Row>
      <table id="homeScreen">
        <thead>
          <tr>{requestsInfo.map(({ name, link }, index) => <th key={index}><Link to={link}>{name}<i className="fas fa-external-link-alt"></i></Link></th>)}</tr>
        </thead>
        <tbody>
          <tr>
            {requestsInfo.map((_, index) => <td key={index}>{requestsCount[index]}</td>)}
          </tr>
        </tbody>
      </table>
      <Row className="my-3">
        <Col>
        </Col>
      </Row>
      <Row className="my-3">
        <Col>
          <a href="/assets/ecrs.csv" target="_blank" rel="noopener noreferrer download">
            <Button>
              ECR Report
            </Button>
          </a>
        </Col>
      </Row>
      <p>ECR Aging Report is generated every Monday at 08:00 AM.</p>
      <p>Last Generated Report: {lastMonday.toLocaleDateString('en-US')} 08:00 AM.</p>
      <p>Next Generated Report: {nextMonday.toLocaleDateString('en-US')} 08:00 AM.</p>

      {/* <iframe title="Product Dev Dashboard" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=50d0f75e-4868-4608-8fd7-53b71f9060ec&autoAuth=true&ctid=5b2bec94-1cc1-4d2a-8dab-d1e985e50e04" frameborder="0" allowFullScreen="true"></iframe> */}

    </Container>
  );
};
export default HomeScreen;