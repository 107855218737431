import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createRequest } from "../actions/requestActions";
import { REQUEST_CREATE_RESET } from "../constants/requestConstants";
import Loader from "./Loader";
import Message from "./Message";

const CreateRequestButton = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        loading: loadingCreate,
        error: errorCreate,
        success: successCreate,
        request: createdRequest
    } = useSelector((state) => state.requestCreate);

    useEffect(() => {
        dispatch({ type: REQUEST_CREATE_RESET });
        if(successCreate) history.push(`/request/${createdRequest._id}/edit`);
    }, [successCreate]);

    return (
        <>
            <Button onClick={()=>dispatch(createRequest())}>
                <i className="fas fa-plus"></i> Create New ECR
            </Button>
            {loadingCreate ? <Loader/> : null}
            {errorCreate ? <Message variant="danger">{errorCreate}</Message> : null}
        </>
    )
}

export default CreateRequestButton;