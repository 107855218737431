import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { inviteUsersAction } from "../actions/userActions";
import Chip from "../components/Chip/Chip";
import Message from "../components/Message";
import { VALID_EMAIL_REGEX } from "../constants/constants";

const InviteScreen = ({ location, history }) => {
  const [items, setItems] = useState([]);
  let [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const inviteUsers = async () => {
    if (items.length) {
      const { data, error } = await inviteUsersAction(items);
      if (error) setError(error);
      else {
        setItems([]);
        setMessage(data);
      }
    }
  };

  const onDelete = (value) => setItems(items.filter((item) => value !== item));

  const onEdit = (value) => {
    setValue(value);
    onDelete(value);
  };

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      value = value.trim();
      if (value && isValid(value)) {
        setItems([...items, value]);
        setValue("");
      }
    }
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    let paste = evt.clipboardData.getData("text");
    let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      let toBeAdded = emails.filter((email) => !isInList(email));
      setItems([...items, ...toBeAdded]);
    }
  };

  const isValid = (email) => {
    let err;

    if (isInList(email)) err = `${email} has already been added.`;
    if (!isEmail(email)) err = `${email} is not a valid email address.`;

    if (err) {
      setError(err);
      setTimeout(() => setError(""), 2000);
      return false;
    }

    return true;
  };

  const isInList = (email) => items.includes(email);

  const isEmail = (email) => VALID_EMAIL_REGEX.test(email);

  return (
    <div className="wrapper">
      <>
        <Form>
          <Form.Group>
            {error ? <Message variant="danger">{error}</Message> : <div />}
            {message && <Message variant="success">{message}</Message>}
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              className={"input " + (error && " has-error")}
              type="email"
              value={value}
              placeholder="Type or paste email addresses and press `Enter`..."
              onKeyDown={handleKeyDown}
              onChange={(e) => setValue(e.target.value)}
              onPaste={handlePaste}
            ></Form.Control>
          </Form.Group>
        </Form>
      </>
      {items.map((item, index) => (
        <Chip key={index} item={item} onEdit={onEdit} onDelete={onDelete} />
      ))}
      <br />
      <br />
      <Button variant="primary" onClick={inviteUsers}>
        Invite users
      </Button>
    </div>
  );
};

export default InviteScreen;
