import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { listUsersForEcr, userLogin } from '../actions/userActions';
import { listGroupDetails, updateGroup } from '../actions/groupActions';
import { GROUP_UPDATE_RESET } from '../constants/groupConstants';
import MultiSelect from 'react-multiple-select-dropdown-lite';

const GroupEditScreen = ({ match, history }) => {
  const groupId = match.params.id;

  const [name, setName] = useState('');
  const [usersIncluded, setUsersIncluded] = useState([]);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userListForEcr = useSelector((state) => state.userListForEcr);
  const {
    loading: loadingUserList,
    error: errorUserList,
    users,
  } = userListForEcr;

  let userOptions = [];
  let usersInitial = [];
  if (users && users.length > 0) {
    userOptions = users.map((p) => {
      return {
        label: p.name,
        value: p._id,
        style: {
          color: 'blue',
        },
      };
    });
    usersInitial = usersIncluded;
  }

  const groupDetails = useSelector((state) => state.groupDetails);
  const { loading, error, group } = groupDetails;

  const groupUpdate = useSelector((state) => state.groupUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = groupUpdate;

  const handleOnchange = (val) => {
    setUsersIncluded(val.split(','));
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(listUsersForEcr());
    }
    if (successUpdate) {
      dispatch({ type: GROUP_UPDATE_RESET });
      history.push('/admin/grouplist');
    } else {
      if (!group.name || group._id !== groupId) {
        dispatch(listGroupDetails(groupId));
      } else {
        setName(group.name);
        setUsersIncluded(group.users);
      }
    }
  }, [dispatch, history, groupId, group, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateGroup({
        _id: groupId,
        name,
        users: usersIncluded,
      })
    );
  };

  return (
    <>
      <Link to="/admin/grouplist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Group</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="usersIncluded">
              <Form.Label style={{ color: 'black', fontSize: '1.2rem' }}>
                Users Included
              </Form.Label>
              <MultiSelect
                style={{ width: '100%' }}
                defaultValue={usersInitial}
                onChange={handleOnchange}
                options={userOptions}
              />
            </Form.Group>

            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default GroupEditScreen;
